<template>
  <div class="contactUs">
    <div class="blueTop">
      <div class="fluid container">
        <div class="commonWhiteBg">
          <a-divider class="topTitle">期待我们的合作</a-divider>
          <div class="formwrap">
            <el-form :label-position="labelPosition" ref="form"     :rules="rules"  label-width="80px" :model="formLabelAlign">
              <el-form-item label="联系人姓名"    prop="name">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="公司名称"    prop="company">
                <el-input v-model="formLabelAlign.company"></el-input>
              </el-form-item>
              <el-form-item label="手机电话"    prop="mobile">
                <el-input v-model="formLabelAlign.mobile"></el-input>
              </el-form-item>
              <el-form-item label="邮箱地址"    prop="address">
                <el-input v-model="formLabelAlign.address"></el-input>
              </el-form-item>
              <el-form-item class="submitRow">
                <el-button type="primary" @click="onSubmit">提交信息</el-button>
              </el-form-item>
            </el-form>
            <p>收到您的信息后，后我们将第一时间与您联系</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reportSubmit} from '@/api/UserService'
export default {
  name: 'ContactUs',
  components:{},
  data () {
    return {
      labelPosition: 'top',
        formLabelAlign: {
          name: '',
          company: '',
          mobile: '',
          address: ''
        },
          rules: {
        name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
         company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机电话', trigger: 'blur' },
          { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的手机电话', trigger: 'blur' }
        ],
      },
    }
  },

  methods: {
    async onSubmit(){
       const formBaseResult = await this.$refs['form'].validate()
       if(!formBaseResult) return
         const params={
                      reportContent:JSON.stringify(this.formLabelAlign)
                  }
                 
                reportSubmit(params).then((data)=>{
                      this.$message.success('提交成功');
                    this.$refs['form'].resetFields();
              }).catch(e=>{
                  console.log(e);
              });
         
    }
  }
}
</script>

<style lang='scss' scoped>
.blueTop {
  background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/blueBg.svg') repeat-x;
  background-size: auto 224px;
  padding-top: 56px;
}
.contactUs{
  .topTitle{
    font-size: 24px;
    color: rgba(0, 18, 22, 0.65);
    margin-bottom: 32px;
  }
  .commonWhiteBg{
    width: 560px;
    padding: 32px 56px 56px 56px;
  }
  p{color: rgba(0, 18, 22, 0.45);text-align: center;}
}
.formwrap{
  width: 100%;
  margin: 0 auto;
  /deep/.el-form--label-top .el-form-item__label{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  /deep/.el-form-item{margin-bottom: 16px;}
  .el-button{width: 100%;margin-top: 16px;}
  
}
@media screen and (min-width: 1px) and (max-width: 767px) {
  .blueTop{padding-top: 24px;}
  .contactUs{
    .topTitle{
      font-size: 18px;
      color: rgba(0, 18, 22, 0.65);
      padding-top: 32px;
      margin-bottom: 16px;
    }
    .commonWhiteBg{
      width: 100%;
      padding: 0 24px 32px 24px;
    }
    .formwrap{
    width: 100%;
    }
  }
  
}
</style>
